@charset "UTF-8";
#footer-copyright {
  margin: 0 40px;
}

@media (min-width: 1280px) {
  #footer-copyright {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.engages-body-con > .box-wc {
  background: #F4F6FC;
  width: 100%;
}

@media (min-width: 1024px) {
  .engages-body-con > .box-wc {
    display: table;
  }
}

@media (min-width: 1024px) {
  header {
    display: table-row;
    height: 1px;
  }
}

main {
  height: 100%;
}

@media (min-width: 1024px) {
  main {
    display: table-row;
  }
}

main > .box-wc {
  height: 100%;
}

@media (min-width: 1024px) {
  main > .box-wc {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

main .left-split, main .right-split {
  padding: 0 30px;
}

@media (min-width: 1024px) {
  main .left-split, main .right-split {
    padding: 60px;
    width: 50%;
    min-height: 650px;
    position: relative;
  }
}

main .left-split {
  text-align: center;
}

@media (min-width: 1024px) {
  main .left-split {
    text-align: left;
    background: url(/_design/local/login-bg.jpg) no-repeat center;
    background-size: cover;
  }
}

main .left-split .text {
  margin: 0;
  padding: 0;
}

main .left-split h1 {
  display: inline-block;
  color: rgb(var(--color-brand-accent));
  max-width: 450px;
  margin: 30px auto;
  line-height: 1.2;
  text-align: center;
  font-size: 2rem;
  -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
}

@media (min-width: 1024px) {
  main .left-split h1 {
    position: relative;
    margin: 0 10px 0 0;
    left: 10px;
    text-align: left;
    background: rgba(var(--color-brand-accent), .85);
    color: #fff;
    display: inline;
    line-height: 1.6;
    padding: 0;
    font-size: 3.75rem;
    text-transform: uppercase;
    -webkit-box-shadow: 10px 0 0 rgba(var(--color-brand-accent), .85), -10px 0 0 rgba(var(--color-brand-accent), .85);
            box-shadow: 10px 0 0 rgba(var(--color-brand-accent), .85), -10px 0 0 rgba(var(--color-brand-accent), .85);
  }
}

main .login-con {
  background: #fff;
  width: 100%;
  max-width: 450px;
  padding: 30px;
  -webkit-box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.18);
          box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.18);
  border-radius: 7px;
  margin: 0 auto;
  color: #4a4a4b;
}

@media (min-width: 1024px) {
  main .login-con {
    padding: 45px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: calc(50% - 60px);
  }
}

main .login-con .error {
  position: relative;
  background-color: #FFBABA;
  border: 1px solid #E53140;
  color: #E53140;
  padding: 7px;
  padding-left: 30px;
  display: block;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

main .login-con .error:before {
  content: "ï—";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}

main .login-con .error a {
  color: #E53140;
  margin-left: 2px;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

main .login-con .error a:hover {
  text-decoration: none;
}

main .login-con .success {
  position: relative;
  background-color: #e0faf1;
  border: 1px solid #02C27E;
  color: #02C27E;
  padding: 7px;
  padding-left: 30px;
  display: block;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

main .login-con .success:before {
  content: "ï˜";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}

main .login-con .success a {
  color: #02C27E;
  margin-left: 2px;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

main .login-con .success a:hover {
  text-decoration: none;
}

main .login-con .info {
  position: relative;
  background-color: #BDE5F8;
  border: 1px solid #1C74BA;
  color: #1C74BA;
  padding: 7px;
  padding-left: 30px;
  display: block;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

main .login-con .info:before {
  content: "ïš";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}

main .login-con .info a {
  color: #1C74BA;
  margin-left: 2px;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

main .login-con .info a:hover {
  text-decoration: none;
}

main .login-con .message {
  font-size: 1rem;
}

main .login-con .message:before {
  top: 12px;
}

main .login-con .title {
  margin: 0;
  padding: 0;
}

main .login-con .title h2 {
  font-size: 1.75rem;
  margin: 0 0 .25em;
  font-weight: 400;
  line-height: 1;
  text-align: left;
  color: #4a4a4b;
}

main .login-con .title p {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 15px;
}

main .login-con label {
  display: block;
  font-size: 1rem;
}

main .login-con input {
  font-size: 1rem;
  padding: 8px 10px;
  border: 1px solid #cfcfcf;
  width: 100%;
}

main .login-con .actions {
  text-align: right;
}

main .login-con .btn {
  display: block;
  background: rgb(var(--color-brand-main));
  -webkit-appearance: none;
  color: #fff;
  border: none;
  border-radius: 2rem;
  letter-spacing: 2px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 700;
  margin: 15px 0 0 auto;
  text-transform: uppercase;
  cursor: pointer;
}

main .login-con .message.important {
  position: relative;
  background-color: #FFBABA;
  border: 1px solid #E53140;
  color: #E53140;
  padding: 7px;
  padding-left: 30px;
  display: block;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
  margin-bottom: 10px;
}

main .login-con .message.important:before {
  content: "ï—";
  font-family: "Font Awesome 5 Pro";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: inherit;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 7px;
  top: 8px;
}

main .login-con .message.important a {
  color: #E53140;
  margin-left: 2px;
  font-family: "Open Sans", Arial, Verdana, sans-serif;
}

main .login-con .message.important a:hover {
  text-decoration: none;
}
